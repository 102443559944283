import React, { useEffect, useRef } from 'react'
import styles from './home.module.scss'
import { useDeviceStore } from '../../stateManager/DeviceStore'
import { DEVICE, PLATFORM, THEME } from '../../_shared/constants/constants'
import Message_header from '../../assets/webp/message_header_desktop.webp'
// import Message_header_Mobile from '../../assets/webp/Message_header_mobile.webp'
import Iphone_desktop from '../../assets/webp/pixel_download.webp'
import Iphone_Mobile from '../../assets/webp/iphone_Message_mobile.webp'
import QR_code from '../../assets/webp/Camera_QR_Code.webp'
import Message_Illustration1 from '../../assets/webp/illustration_1_desktop.webp'
import Message_Illustration1_mobile from '../../assets/webp/illustration_1_mobile.webp'
import Message_Illustration2 from '../../assets/webp/illustration_2.webp'
import Message_Illustration2_mobile from '../../assets/webp/illustration_2_mobile.webp'
import Message_Illustration3 from '../../assets/webp/illustration_3.webp'
import Message_Illustration4 from '../../assets/webp/illustartion_4.webp'
import Message_Illustration4_mobile from '../../assets/webp/illustration_4_mobile.webp'
import SetsApart_desktop from '../../assets/webp/setsApart_desktop.webp'
import SetsApart_mobile from '../../assets/webp/setsApart_Mobile.webp'
import Iphone_footer from '../../assets/webp/pixel_Footer.webp'
// import InvisiblePhotos from '../../assets/webp/invisible_photos_mobile.webp'
// import DesktopPoster from '../../assets/webp/messageDesktopVideoPoster.webp'
// import MobilePoster from '../../assets/webp/messageMobileVideoPoster.webp'

import DesktopPoster from '../../assets/webp/video_poster_desktop.webp'
import MobilePoster from '../../assets/webp/video_poster_mobile.webp'

import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_icon.svg'
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_icon.svg'

import { ReactComponent as MessageFeature1 } from '../../assets/svg/message_feature1.svg'
import { ReactComponent as MessageFeature2 } from '../../assets/svg/message_feature2.svg'
import { ReactComponent as MessageFeature3 } from '../../assets/svg/message_feature3.svg'
import { ReactComponent as MessageFeature4 } from '../../assets/svg/message_feature4.svg'
import { ReactComponent as MessageFeature5 } from '../../assets/svg/message_feature5.svg'
// import { ReactComponent as MessageFeature6 } from '../../assets/svg/message_feature6.svg'
import { Link } from 'react-router-dom'
import { useDownloadHook } from '../../_shared/hooks/download.hook'
import { useOsStore } from '../../stateManager/OsStore'

import { lazily } from 'react-lazily';
const { FloaterDownload } = lazily(() => import('../../_shared/components/FloaterDownload/floaterDownload.component'));
const { PromotionalBanner } = lazily(() => import('../../_shared/components/PromotionalBanner/promotionalBanner.component'));


function Home() {
    const { device, setDevice } = useDeviceStore();
    const {
        handleBuildDownload,
    } = useDownloadHook();
    const { os } = useOsStore();
    const videoRef = useRef<HTMLVideoElement | null>(null)

    useEffect(() => {
        if (videoRef.current) videoRef.current.playbackRate = .7;

    }, [])

    return (
        <div className={styles.homeContainer}>
            <div className={styles.homeChildContainer}>
                <FloaterDownload title='To protect your messages' buttonText='Downalod' mode={`${THEME.LIGHT}`} />
                <div className={styles.headerContainer}>
                    <div className={styles.header}>
                        <div className={styles.heading}>
                            Keep your private life <span>private.</span>
                        </div>
                        <div className={styles.subHeading}>
                            Seamless Conversations with Total Privacy.
                        </div>

                    </div>
                    <div className={styles.headerImg}>
                        {/* <img src={device == DEVICE.MOBILE ? `${Message_header}` : `${Message_header}`} alt='Zunu mail Header' /> */}
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            preload="auto"
                            // controls={device !== DEVICE.MOBILE}
                            className={styles.video}
                            poster={device === DEVICE.MOBILE ? MobilePoster : DesktopPoster}
                            ref={videoRef}
                        >
                            <source src={`assets/webm/message_introduction_mobile.webm`} type={`video/webm`} media="(max-width:730px)" />
                            <source src={`assets/mp4/message_introduction_mobile.mp4`} type={`video/mp4`} media="(max-width:730px)" />
                            <source src={`assets/webm/message_introduction_desktop.webm`} type={`video/webm`} />
                            <source src={`assets/mp4/message_introduction_desktop.mp4`} type={`video/mp4`} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <PromotionalBanner />

                <div className={styles.messageContentContainer}>
                    <div className={styles.downloadContainer}>
                        <div className={styles.downloadLeftContainer}>
                            <p className={styles.downloadHeader}>Download Zunu Message now and keep conversations completely private.</p>

                            <div className={styles.download_Os_container}>
                                {device !== DEVICE.MOBILE &&
                                    <div className={styles.downloadBtnContainer}>

                                        <button className={styles.downloadBtn} onClick={() => handleBuildDownload(os)} id="banner1Download">Download Now</button>

                                        <div className={styles.osLogoContainer}>
                                            <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)} id="banner1PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                                        </div>

                                    </div>
                                }
                            </div>

                        </div >
                        <div className={styles.downloadImgContainer}>
                            <img src={device == DEVICE.MOBILE ? `${Iphone_desktop}` : `${Iphone_desktop}`} />
                        </div>


                        {
                            device == DEVICE.MOBILE &&
                            <div className={styles.osLogoContainer}>
                                <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                            </div>
                        }
                    </div >

                </div >

                <div className={`${styles.messageFeatures_Container}  ${styles.messageContentContainer}`}>
                    <div className={`${styles.messageFeatures_header}`}>
                        Your Message Stays Private and Protected.
                    </div>
                    <div className={styles.features_content}>
                        <div className={styles.messageFeatureContainer}>
                            <div className={styles.message_feature}>
                                <MessageFeature1 className={styles.featureIcons} />
                                <div className={styles.descriptionTextContainer}>
                                    <p className={styles.featureDescription}>
                                        Protected, Invisible Messages:
                                    </p>
                                    <p className={styles.featureDescriptionText}>Messaging the way it should be—secure, private, and fully in user control.</p>
                                </div>
                            </div>
                        </div>

                        <div className={styles.messageFeatureContainer}>
                            <div className={styles.message_feature}>
                                <MessageFeature3 className={styles.featureIcons} />
                                <div className={styles.descriptionTextContainer}>
                                    <p className={styles.featureDescription}>
                                        Secure on Device:
                                    </p>
                                    <p className={styles.featureDescriptionText}>Prevents third-party apps with SMS inbox access from reading messages.</p>
                                </div>
                            </div>
                        </div>

                        <div className={styles.messageFeatureContainer}>
                            <div className={styles.message_feature}>
                                <MessageFeature5 className={styles.featureIcons} />
                                <div className={styles.descriptionTextContainer}>
                                    <p className={styles.featureDescription}>
                                        Secure over Network:
                                    </p>
                                    <p className={styles.featureDescriptionText}>Messages are encrypted in transit, hidden from telecom providers.</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.messageFeatureContainer}>
                            <div className={styles.message_feature}>
                                <MessageFeature2 className={styles.featureIcons} />
                                <div className={styles.descriptionTextContainer}>
                                    <p className={styles.featureDescription}>
                                        Defence Against Hackers:
                                    </p>
                                    <p className={styles.featureDescriptionText}>Messages are protected from ransomware, malware, and viruses.</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.messageFeatureContainer}>
                            <div className={styles.message_feature}>
                                <MessageFeature4 className={styles.featureIcons} />
                                <div className={styles.descriptionTextContainer}>
                                    <p className={styles.featureDescription}>
                                        Confident Communication:
                                    </p>
                                    <p className={styles.featureDescriptionText}>Messages remain private, even if you or the recipient lose the device.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.protectedMessageContainer} ${styles.messageContentContainer}`}>
                    <div className={styles.messageHeader}>
                        <div className={`${styles.messageFeatures_header}  ${styles.messageFeatures1_header}`}>
                            Protected, Invisible Messages
                        </div>
                        {device == DEVICE.MOBILE &&
                            <p className={styles.messageSubHeader}>Total Privacy in Communication. Only the sender and recipient can view messages. </p>
                        }
                    </div>
                    <div className={styles.protectedMessageImg}>
                        <img src={device == DEVICE.MOBILE ? `${Message_Illustration1_mobile}` : `${Message_Illustration1}`} alt='mail illustration' />
                    </div>

                </div>

                <div className={`${styles.messagePrivacyContainer} ${styles.messageContentContainer}`}>
                    <div className={styles.messageHeader}>
                        <div className={`${styles.messageFeatures_header} `}>
                            Secure on Device
                        </div>
                      {device == DEVICE.MOBILE &&
                        <p className={styles.secureDeviceText}>Message Privacy Assured: Apps with SMS Inbox access cant access messages</p>
                      }
                    </div>
                    <div className={styles.secureDeviceimg}>
                    {device !== DEVICE.MOBILE &&
                        <p className={styles.secureDeviceText}>Message Privacy Assured: Apps with SMS Inbox access cant access messages</p>
                      }
                        <img src={device == DEVICE.MOBILE ? `${Message_Illustration2_mobile}` : `${Message_Illustration2}`} alt='illustration' />
                    </div>
                </div>

                <div className={`${styles.secureNetworkContainer} ${styles.messageContentContainer}`}>
                    <div className={styles.messageHeader}>
                        <div className={styles.messageFeatures_header}>
                            Secure Over Network
                        </div>
                      {device == DEVICE.MOBILE &&
                        <p className={`${styles.illustrationSubHeader} ${styles.illustrationSubHeader3}`}>Secure Messaging: Hidden and Protected on the Network.</p>
                      }
                    </div>
                    <div className={`${styles.networkTextImgcontainer}`}>
                        <div className={styles.netwokTextContainer}>
                        {device !== DEVICE.MOBILE &&
                        <p className={`${styles.illustrationSubHeader} ${styles.illustrationSubHeader3}`}>Secure <span>Messaging:</span> Hidden <span>and</span> Protected <span>on the Network.</span></p>
                      }
                        </div>
                        <div className={styles.networkImgContainer}>
                            <img src={Message_Illustration3} alt='mail illustration' />
                        </div>
                    </div>
                </div>

                <div className={`${styles.illustrationContainer} ${styles.messageContentContainer}`}>
                    <div className={styles.messageHeader}>
                        <div className={styles.messageFeatures_header}>
                            Message Confidently
                        </div>
                        {device == DEVICE.MOBILE &&
                            <div className={styles.confidentialityText}>
                                {device == DEVICE.MOBILE &&

                                    <p className={styles.illustrationSubHeader}>Message confidentiality and privacy are maintained even during  device loss and password compromise.</p>
                                }
                            </div>
                        }
                    </div>
                    <div className={styles.messageTextImgContainer}>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.confidentialityText}>
                                {device !== DEVICE.MOBILE &&
                                    <p className={styles.illustrationSubHeader}><span>Message</span> confidentiality <span>and</span> privacy <span>are maintained even during</span>  device loss <span>and</span> password compromise.</p>
                                }

                            </div>
                        }
                        <div className={styles.confidentialityImg}>
                            <img src={device == DEVICE.MOBILE ? `${Message_Illustration4_mobile}` : `${Message_Illustration4}`} alt='mail illustration' />
                        </div>
                    </div>
                </div>

                <div className={`${styles.setUsApartContainer} ${styles.messageContentContainer}`}>
                    <div className={styles.setApartHeader}>
                        <div className={styles.messageFeatures_header}>
                            What sets us Apart?
                        </div>
                        <div className={styles.messageFeatureSubHeader}>Our ultimate robust Device-to-Device End-to-End Encryption for 100% Privacy</div>

                    </div>
                    <div className={styles.setApartImg}>
                        <img src={device == DEVICE.MOBILE ? `${SetsApart_mobile}` : `${SetsApart_desktop}`} alt='illustration' />
                    </div>
                </div>

                <div className={styles.footerContainer}>
                    <div className={styles.footerLeftContainer}>
                        <p className={styles.footerText_header}>Download Zunu Message now and keep conversations completely private.</p>
                        <div className={styles.download_Qr_scanner}>
                            {device !== DEVICE.MOBILE &&
                                <div className={styles.footerDownload_container}>
                                    <div className={styles.footerBtn_container}>
                                        <button className={styles.downloadBtn} onClick={() => handleBuildDownload(os)} id="banner2Download">Download Now</button>
                                    </div>
                                    {device !== DEVICE.MOBILE &&
                                        <div className={styles.footerLogoContainer}>
                                            <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)} id="banner2PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.footerImgContainer}>
                        <div className={styles.footerImg}>
                            <img src={device == DEVICE.MOBILE ? `${Iphone_footer}` : `${Iphone_footer}`} alt='pixel mobile' />
                        </div>

                        {device == DEVICE.MOBILE &&
                            <div className={styles.footerLogoContainer}>
                                <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)} id="banner2PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export { Home }